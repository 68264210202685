<template>
    <div class="container-fluid">
        <div class="d-flex align-items-center justify-content-between">
            <div class="title-card-tec card-header-title color-primary-reversed" style="font-weight:400">Place de Marché </div>
            <mdb-btn size="sm" color="primary" @click="$router.push('/mes-modeles')">Mes Modèles</mdb-btn>
        </div>
        <p>La place de marché liste les modèles de factures gratuit et payant.</p>
        <CoolLightBox 
            :items="queriedData" 
            :index="index"
            @close="index = null">
        </CoolLightBox>
        <mdb-row v-if="$gate.checkTypeRole(['super-admin','admin'])">
            <mdb-col sm="12" md="3" lg="3"
                class="image mb-4"
                style="position:relative"
                v-for="(image, imageIndex) in queriedData"
                :key="imageIndex" 
                >
            <div > 
                <mdb-card wide style="">
                    <mdb-view hover cascade >
                        <a>
                            <mdb-card-image :src="image.src" alt="Card image cap" style="width:100%;height:200px;" ></mdb-card-image>
                            <mdb-mask flex-center waves overlay="white-slight"></mdb-mask>
                        </a>
                    </mdb-view>
                    <mdb-card-body class=" d-flex justify-content-between align-items-center px-2 py-2" cascade>
                        <div class="title">
                            <div  style="font-weight:500; font-size:0.9rem;">{{image.title}}</div>
                            <div class="type" style="font-size:0.7rem">
                                 <span v-if="image.type === 'free'" class="badge  bg-success text-uppercase" >Gratuit</span>
                                <span v-if="image.type !== 'free'" class="badge  bg-primary text-uppercase" >Payant</span>
                            </div>
                        </div>
                        <div class="f-action d-flex justify-content-between">
                            <mdb-tooltip trigger="hover" :options="{placement: 'bottom'}">
                                <span slot="tip">Voir</span>
                                <button @click="index = imageIndex" slot="reference" style="border:none;background:transparent">
                                    <i class="fas fa-eye text-primary"></i>
                                </button>
                            </mdb-tooltip>
                            <mdb-tooltip trigger="hover" :options="{placement: 'bottom'}">
                                <span slot="tip" v-if="(image.id !== $store.state.auth.model.id)">Appliquer</span>
                                <span slot="tip" v-else>Utilisé</span>
                                <button @click="(image.id !== $store.state.auth.model.id) ? activeModel({
                                    mi:image.id,
                                    type:image.type,
                                    ci:$store.state.auth.company.id}): ''"  
                                    slot="reference" style="border:none;background:transparent">
                                    <i class="fas fa-check-square" :class="(image.code_model !== $store.state.auth.model.code_model)?' text-primary':' text-success'" ></i> 
                                </button>
                            </mdb-tooltip>
                        </div>
                    </mdb-card-body>
                </mdb-card>
            </div>
            </mdb-col>
        </mdb-row>
        <div v-if="(queriedData.length == 0) ? true : false"  class="d-flex justify-content-center w-100 p-5">
            <img :src="require('../../../assets/svg/empty_streec.svg')"  class="empty" alt="" srcset="">
        </div>
        <div class="d-flex justify-content-end">
                <tec-pagination
                v-model="dataPagination.pagination.currentPage"
                :per-page="parseFloat(dataPagination.pagination.perPage)"
                :total="dataPagination.pagination.total"
            ></tec-pagination>
        </div>
    </div>
</template>

<script>
import TecPagination from '@/tba/components/Pagination' 
import {
    mdbView,
    mdbRow,mdbCol,
    mdbCard,mdbCardBody,
    mdbMask,
    mdbTooltip,

    mdbBtn,
    

    mdbCardImage
} from 'mdbvue'
export default {
    name:"market-place",
    metaInfo: {
      title: 'TECBILL APP',
      titleTemplate: '%s - PLACE DE MARCHE',
      htmlAttrs: {
        lang: 'fr',
        amp: true
      }
    },
    components:{
        mdbView,
        mdbRow,mdbCol,
        mdbCard,mdbCardBody,
        mdbMask,
        mdbTooltip,
        mdbCardImage,
        mdbBtn,
        
        TecPagination, 
    },

    data(){
        return {
            dataPagination: {
                pagination: {
                    perPage: 12,
                    currentPage: 1,
                    perPageOptions: [
                        {text:"5",value:"5", selected: true},
                        {text:"10",value:"10"},
                        {text:"25",value:"25"},
                        {text:"50",value:"50"},
                        {text:"100",value:"100"},
                    ],
                    total: 0,
                },
                searchQuery: "",
                propsToSearch: [],
            },

            data:{
                columns: [],
                rows:[]
            },
            index:null,
            modeles:[],
            empty:false,
            loading:false
        }
    },

    methods:{
        async activeModel(data) { 
            let res = await this.$store.dispatch("swal/ultimate",{
                type:"warning",
                icon:"warning",
                title:"Etes-vous sûre?"
            });
            if (res.isDismissed) {
                return;
            }
            this.$nprogress.start()
            this.$store.commit('setting/SET_CLEAN')
			await this.$store.dispatch('model_invoice/activeModel', data)
			.then((response) => {
                this.$nprogress.done()
                this.$store.dispatch('auth/attempt',localStorage.getItem('token'));
                this.loadModelInvoices()
                this.$notify({
                    message: response.data.message,
                    type: 'success'
                })
                if(data.type !== 'free'){
                   this.$router.push('/mes-modeles');
                }

			})
			.catch((error) => {
                this.$nprogress.done()
                this.submitBtn = !this.submitBtn
                if (error.response.data.errors) {
                    this.$store.commit('setting/SET_RF', error.response.data.errors.regime_fiscal)
                    this.$store.commit('setting/SET_NIM', error.response.data.errors.nim)
                    this.$store.commit('setting/SET_SIGN', error.response.data.errors.signatory)
                    this.$store.commit('setting/SET_ST', error.response.data.errors.signatory_title) 
                }
                this.$notify({
                       
                    message: error.response.data.message  || error.response.data.error,
                    type: 'danger'
                })
			})  
        },
        reinit(){
            this.data.rows = [];
            this.modeles = [];
        },
        async loadModelInvoices () {

			this.$nprogress.start()
            await this.$store.dispatch('model_invoice/findAll',{
                mode:"simple"
            })
            .then(response => {
                this.reinit()
                this.$nprogress.done()
                this.modeles = response.data.modeles.filter(model => model.code_model !== this.$store.state.auth.model.code_model)
                this.modeles.splice(0,0,this.$store.state.auth.model)
                this.modeles.forEach(m => {
                    this.data.rows.push({
                        title:m.name,
                        description:"",
                        src:this.$store.state.url+'storage/models/'+m.image,
                        currently:false,
                        code_model:m.code_model,
                        type:m.type,
                        id:m.id,
                    })
                })
            }).catch((error) => {
				this.$nprogress.done()
                this.$notify({
                       
                    message: error.response.data.message  || error.response.data.error,
                    type: 'danger'
                })
            })
        },
        // Systeme de datatable
        totalCalcul () {
            return this.dataPagination.pagination.total = this.data.rows.length;
        },

        searchQ () {
            if (!this.dataPagination.searchQuery) {
                // return this.pagedData;
                return new Error('Nothing To Search')
            }
            
            let result = this.data.rows.filter((row) => {
                let isIncluded = false;
                for (let key of this.dataPagination.propsToSearch) {
                    let rowValue = "";
                    rowValue = row[key].toString().toLowerCase();
                    if (rowValue.includes && rowValue.includes(this.dataPagination.searchQuery.toLowerCase())) {
                    isIncluded = true;
                    }
                }
                return isIncluded;
            });
            this.dataPagination.pagination.total = result.length;
            return result.slice(this.from, this.to);
        },

        nbrPerPageSelected(value){
            let nbr = value;
            
            return this.nbrPerPage(nbr)
        },

        nbrPerPage (nbr) {
           return this.dataPagination.pagination.perPage = nbr
        },
    },

    created() {
        this.loadModelInvoices()
    },

    computed: {
        pageData () {
            return this.data.rows.slice(this.from, this.to);
        },

        to() {
            let highBound = this.from + this.dataPagination.pagination.perPage;
            if (this.total < highBound) {
                highBound = this.total;
            }
            return highBound;
        },

        queriedData() {
            let result = this.searchQ();
            return (result instanceof Error) ? this.pageData : result;
        },

        from() {
            return this.dataPagination.pagination.perPage * (this.dataPagination.pagination.currentPage - 1);
        },

        total() { 
            return this.totalCalcul();
        },
    }
}
</script>